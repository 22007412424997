var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.years,"label":"Año"},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-select',{attrs:{"items":_vm.clientes,"loading":_vm.clientsLoading,"item-text":"name","item-value":"id","multiple":"","label":"Cliente"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectedClients.length > 0 ? 'primary darken-4' : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0 && _vm.selectedClients.length === 1)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1 && _vm.selectedClients.length === _vm.clientes.length)?_c('span',{staticClass:"caption"},[_vm._v(" All ")]):_vm._e(),(index === 1 && _vm.selectedClients.length < _vm.clientes.length)?_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.selectedClients.length)+" Clientes ")]):_vm._e()]}}]),model:{value:(_vm.selectedClients),callback:function ($$v) {_vm.selectedClients=$$v},expression:"selectedClients"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-5",class:hover ? 'darken-3' : 'darken-2',attrs:{"color":"indigo","dark":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"display-1 font-weight-black"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.computedFacturado))+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"title font-weight-light"},[_vm._v(" Facturado ")])])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-5",class:hover ? 'darken-3' : 'darken-2',attrs:{"color":"indigo","dark":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"display-1 font-weight-black"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.computedPorFacturar))+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"title font-weight-light"},[_vm._v(" Backorder ")])])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-5",class:hover ? 'darken-3' : 'darken-2',attrs:{"color":"indigo","dark":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"display-1 font-weight-black"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.computedNeto))+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"title font-weight-light"},[_vm._v(" Total ")])])],1)],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"id":"ventas-producto"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Facturación Anual")]),_c('v-spacer')],1),_c('v-divider',{staticClass:"mb-3 primary"}),_c('v-card-text',{staticClass:"pa-3",staticStyle:{"height":"auto"}},[_c('v-chart',{ref:"chartDiaria",staticClass:"ma-auto",staticStyle:{"width":"100%"},attrs:{"theme":"walden","options":_vm.chartDiaria,"autoresize":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-select v-model="selectedYear" :items="years" label="Año"> </v-select>
      </v-col>
      <v-col cols="12" md="5">
        <v-select
          v-model="selectedClients"
          :items="clientes"
          :loading="clientsLoading"
          item-text="name"
          item-value="id"
          multiple
          label="Cliente"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon
                  :color="selectedClients.length > 0 ? 'primary darken-4' : ''"
                >
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Todos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0 && selectedClients.length === 1">{{
              item.name
            }}</span>
            <span
              v-if="index === 1 && selectedClients.length === clientes.length"
              class="caption"
            >
              All
            </span>
            <span
              v-if="index === 1 && selectedClients.length < clientes.length"
              class="caption"
            >
              {{ selectedClients.length }} Clientes
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="pa-5"
            color="indigo"
            dark
            :class="hover ? 'darken-3' : 'darken-2'"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span class="display-1 font-weight-black">
                  {{ computedFacturado | toCurrency }}
                </span>
              </v-col>
              <v-col cols="12" class="text-center">
                <span class="title font-weight-light"> Facturado </span>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="pa-5"
            color="indigo"
            dark
            :class="hover ? 'darken-3' : 'darken-2'"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span class="display-1 font-weight-black">
                  {{ computedPorFacturar | toCurrency }}
                </span>
              </v-col>
              <v-col cols="12" class="text-center">
                <span class="title font-weight-light"> Backorder </span>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="pa-5"
            color="indigo"
            dark
            :class="hover ? 'darken-3' : 'darken-2'"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span class="display-1 font-weight-black">
                  {{ computedNeto | toCurrency }}
                </span>
              </v-col>
              <v-col cols="12" class="text-center">
                <span class="title font-weight-light"> Total </span>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card id="ventas-producto">
          <v-toolbar flat>
            <v-toolbar-title> Facturación Anual</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider class="mb-3 primary" />
          <v-card-text class="pa-3" style="height: auto">
            <v-chart
              ref="chartDiaria"
              theme="walden"
              class="ma-auto"
              style="width: 100%"
              :options="chartDiaria"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ECharts from "vue-echarts";
// import ECharts modules manually to reduce bundle size
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";
import theme from "@/assets/branding/chartTheme.json";
import { TweenMax } from "gsap";

ECharts.registerTheme("walden", theme);

export default {
  name: "Anual",
  components: {
    "v-chart": ECharts,
  },
  data: (vm) => ({
    selectedYear: new Date().getFullYear(),
    clientsLoading: false,
    years: [],
    meses: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    clientes: [],
    selectedClients: [],
    chartDiaria: {
      tooltip: {
        confine: true,
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: function (params) {
          let output = "<b>" + params[0].name + "</b><br/>";
          let total = params.reduce((tot, b) => tot + b.value, 0);
          for (let i = 0; i < params.length; i++) {
            output +=
              params[i].marker +
              params[i].seriesName +
              ": " +
              vm.$options.filters.toCurrency(params[i].value); // : every 2nth

            if (i !== params.length - 1) {
              // Append a <br/> tag if not last in loop
              output += "<br/>";
            }
          }
          output += "<br/><br/>Total: " + vm.$options.filters.toCurrency(total);
          return output;
        },
      },
      legend: {
        data: ["Facturado", "Por Facturar"],
      },
      xAxis: {
        type: "category",
        data: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ],
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "${value}",
        },
      },
      series: [
        {
          name: "Facturado",
          type: "bar",
          stack: "total",
          // barGap: "-100%",
          data: [],
        },
        {
          name: "Por Facturar",
          // barGap: "-100%",
          type: "bar",
          stack: "total",
          data: [],
        },
        // {
        //   name: "No Facturado",
        //   barGap: "-100%",
        //   type: "bar",
        //   itemStyle: {
        //     color: "#d40808",
        //   },
        //   data: [],
        // },
      ],
    },
    facturado: 0,
    tweenPorFacturar: 0,
    porFacturar: 0,
    tweenPorFaturar: 0,
    neto: 0,
    tweenNeto: 0,
  }),
  computed: {
    computedFacturado() {
      return this.tweenPorFacturar;
    },
    computedPorFacturar() {
      return this.tweenPorFaturar;
    },
    computedNeto() {
      return this.tweenNeto;
    },
    allClients() {
      return this.selectedClients.length === this.clientes.length;
    },
    algunosClientes() {
      return this.selectedClients.length > 0 && !this.allClients;
    },
    icon() {
      if (this.allClients) return "mdi-close-box";
      if (this.algunosClientes) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    selectedYear() {
      this.getYearReport();
    },
    selectedClients() {
      this.getYearReport();
    },
    facturado(newValue) {
      TweenMax.to(this.$data, 0.7, { tweenPorFacturar: newValue });
    },
    porFacturar(newValue) {
      TweenMax.to(this.$data, 0.7, { tweenPorFaturar: newValue });
    },
    neto(newValue) {
      TweenMax.to(this.$data, 0.7, { tweenNeto: newValue });
    },
  },
  mounted() {
    this.generateYears();
    this.getClients();
  },
  methods: {
    getClients() {
      // this.$store.dispatch("working", true);
      this.clientsLoading = true;
      this.$http
        .get("getClients")
        .then((res) => {
          this.clientes = res.data.data;
          this.toggle();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              this.$toast.error(error.response.data.msg);
            } else {
              this.$toast.error("Ocurrio un error inesperado.");
            }
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.clientsLoading = false;
          // this.$store.dispatch("working", false);
        });
    },
    getYearReport() {
      // this.clientsLoading = true;
      this.$refs.chartDiaria.chart.showLoading("default", {
        text: "Cargando Datos",
      });
      this.$http
        .post("invoice/getYearReport", {
          year: this.selectedYear,
          clients: this.selectedClients,
        })
        .then((res) => {
          // this.clientes = res.data.data;
          this.facturado = res.data.facturado;
          this.porFacturar = res.data.total;
          this.neto = res.data.total + res.data.facturado;
          this.$nextTick(() => {
            this.$refs.chartDiaria.chart.setOption({
              series: [
                {
                  name: "Facturado",
                  data: res.data.chartTotal,
                },
                {
                  name: "Por Facturar",
                  data: res.data.porFacturar,
                },
              ],
            });
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              this.$toast.error(error.response.data.msg);
            } else {
              this.$toast.error("Ocurrio un error inesperado.");
            }
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$refs.chartDiaria.chart.hideLoading();
        });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allClients) {
          this.selectedClients = [];
        } else {
          this.selectedClients = this.clientes.map((a) => a.id);
        }
      });
    },
    generateYears() {
      this.years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);
    },
  },
};
</script>

<style scoped></style>
